import React, { FC, ReactNode, useEffect, useState } from "react";
import { CSSObject, useTheme } from "@emotion/react";
import { useResponsive } from "ahooks";
import { m, useReducedMotion } from "framer-motion";
import Select, {
  ControlProps,
  GroupBase,
  InputActionMeta,
  MultiValue,
  OptionProps,
  SingleValue,
} from "react-select";
import { useNavigate } from "react-router-dom";
import { MainSearchMenuListStyles, searchContainer } from "./styles";
import CustomDropdownIndicator from "@components/CustomReactSelect/CustomDropdownIndicator";
import {
  DropdownIndicatorStyles,
  GroupHeadingStyles,
  GroupStyles,
  OptionStyles,
  PlaceholderStyles,
  ControlStyles,
} from "@components/CustomReactSelect/styles";
import { URLS } from "@constants/urls";
import { formatOptionLabel, formatSearchResults, GroupHeading } from "@utils/helpers";
import { formatGroupLabel, noOptionsMessage, translationsList } from "@utils/helpers/spotlight";
import {
  SearchResults,
  SpotlightBranch,
  SpotlightCategory,
  SpotlightCourse,
  SpotlightFile,
  SpotlightGroup,
  SpotlightUnit,
  SystemOption,
} from "types/entities";
import CustomInput from "@components/CustomReactSelect/CustomInput";
import { i18n } from "@utils/i18n";
import { usePostCourseCreate } from "@hooks/api/courses";
import authService from "@utils/services/AuthService";
import userRoles from "@constants/userRoles";
import { variants } from "./constants";
import { Option } from "./types";
import { useConfigurationStore } from "@stores";
import { useApplyTranslations } from "@hooks";

export type MainSearchProps = {
  searchResults: SearchResults | null;
  isLoading?: boolean;
  onSearch: (inputValue: string) => void;
  onClose?: () => void;
};

const MainSearch: FC<MainSearchProps> = ({
  searchResults,
  isLoading = false,
  onSearch,
  onClose,
}) => {
  const { t } = useApplyTranslations();
  const shouldReduceMotion = useReducedMotion();
  const { lg, xl, md } = useResponsive();
  const navigate = useNavigate();
  const { autocompleteInput } = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const isRtl = i18n.dir() === "rtl";
  const { mutate: addCourseMutation } = usePostCourseCreate(navigate);
  const isLearner = authService.getDefaultRole() === userRoles.LEARNER;
  const { userProfileData } = useConfigurationStore();
  const { id: userId } = userProfileData ?? {};
  const userRole = authService.getDefaultRole();

  // Custom styles for the components
  const customStyles = {
    dropdownIndicator: (base: CSSObject): CSSObject => DropdownIndicatorStyles({ base }),
    placeholder: (base: CSSObject): CSSObject => PlaceholderStyles({ autocompleteInput, base }),
    option: (base: CSSObject, state: OptionProps<Option, true, GroupBase<Option>>): CSSObject =>
      OptionStyles({ autocompleteInput, base, state }),
    menuList: (base: CSSObject): CSSObject => MainSearchMenuListStyles({ base }),
    group: (base: CSSObject): CSSObject => GroupStyles({ autocompleteInput, base }),
    groupHeading: (base: CSSObject): CSSObject => GroupHeadingStyles({ base }),
    control: (base: CSSObject, state: ControlProps<Option, true, GroupBase<Option>>): CSSObject =>
      ControlStyles({ autocompleteInput, base, state, status: "" }),
  };

  // filter search results
  const filteredSearchResults = searchResults
    ? Object.keys(searchResults)
        .filter((key) => {
          if (key === "total") return false;
          // For now he hide profile/branch/group files results for admins and instructors (Only return course files)
          if (key === "files" && !isLearner) {
            searchResults[key] = searchResults[key].filter(
              (file) => file.origin && file.origin.type === "course",
            );

            return searchResults[key].length > 0;
          }
          return searchResults[key].length > 0;
        })
        .map((key) => ({
          label: t(translationsList[key]),
          options: searchResults[key],
          type: key,
        }))
    : [];

  // format search result as grouped options
  const formattedOptions = filteredSearchResults.reduce(formatSearchResults, []);

  if (formattedOptions.length > 0) {
    formattedOptions.push({
      label: "detailed-results",
      options: [
        {
          value: "detailed-results",
          label: t("spotlight.viewDetailedResults"),
          avatar: "",
          type: "detailed-results",
        },
      ],
    });
  }

  const handleInputChange = (inputValue: string, action: InputActionMeta): void => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      setInputValue(inputValue);
      onSearch(inputValue);
    }
  };

  const handleChange = (newValue: SingleValue<Option> | MultiValue<Option>): void => {
    const selected = newValue as SingleValue<Option>;

    if (selected && searchResults) {
      const typeResults = searchResults[selected.type];

      // navigate to detailed results
      if (selected.type === "detailed-results") {
        navigate(URLS.search.all, { state: inputValue });
      }

      if (typeResults) {
        // find select item from return results
        const selectedResult = typeResults.find(
          (
            result:
              | SystemOption
              | SpotlightFile
              | SpotlightCourse
              | SpotlightUnit
              | SpotlightBranch
              | SpotlightGroup
              | SpotlightCategory,
          ) => result.id.toString() === selected.value,
        );

        if (selectedResult) {
          if (selected.type === "options") {
            if (selected.value === "addCategory")
              navigate(selectedResult.redirectUrl, { state: { openDrawer: true } });
            else if (selected.value === "addCourse") {
              addCourseMutation(navigate);
            } else if (selected.value === "myCourses" && !isLearner) {
              if (authService.getDefaultRole() === userRoles.INSTRUCTOR)
                navigate(URLS.courses.courses);
              else
                navigate(
                  URLS.users.getUserLink({
                    userId: userId?.toString() ?? "",
                  }),
                );
            } else if (
              selected.value === "myGroups" &&
              authService.getDefaultRole() === userRoles.ADMINISTRATOR
            ) {
              navigate(
                URLS.users.getUserLink({
                  userId: userId?.toString() ?? "",
                }),
                {
                  state: { isGroupActive: true },
                },
              );
            } else if (selectedResult.redirectionState) {
              navigate(selectedResult.redirectUrl, { state: selectedResult.redirectionState });
            } else navigate(selectedResult.redirectUrl);
          }

          if (selected.type === "courses") {
            const navigationLink = isLearner
              ? URLS.user.createCourseLink({ courseId: selectedResult.id })
              : URLS.courses.getCourseEditLink({ courseId: selectedResult.id });

            navigate(navigationLink);
          }

          if (selected.type === "units") {
            const navigationLink = isLearner
              ? URLS.user.createUnitLink({
                  courseId: selectedResult.course.id,
                  unitId: selectedResult.id,
                })
              : URLS.units.getUnitEditLink({
                  courseId: selectedResult.course.id,
                  unitId: selectedResult.id,
                });

            navigate(navigationLink);
          }

          if (selected.type === "files") {
            const origin = selectedResult.origin;

            if (origin?.type !== "course") {
              navigate(URLS.user.files);
            } else {
              if (origin?.id) navigate(URLS.search.all, { state: inputValue });
            }
          }

          if (selected.type === "users") {
            navigate(URLS.users.getUserLink({ userId: selectedResult.id }), {
              state: { isEditing: true },
            });
          }

          if (selected.type === "branches") {
            navigate(URLS.branches.getBranchLink({ branchId: selectedResult.id }));
          }

          if (selected.type === "groups") {
            navigate(URLS.groups.getGroupLink({ groupId: selectedResult.id }), {
              state: { isEditing: true },
            });
          }

          if (selected.type === "categories") {
            navigate(URLS.categories.categories, {
              state: { editDrawer: true, categoryId: selectedResult.id },
            });
          }
        }
      }

      // close mobile search
      if (onClose) {
        onClose();
      }
    }
  };

  // Clear the input value when changing roles
  useEffect(() => {
    setInputValue("");
    onSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  return (
    <m.div
      css={searchContainer}
      data-testid="search-container"
      initial={md ? false : "initial"}
      animate={isExpanded ? "animate" : "hidden"}
      variants={variants}
      custom={{ lg, md, xl, shouldReduceMotion }}
    >
      <Select
        aria-label={t("general.search")}
        // set it undefined to not show the selected option in the search input
        value={null}
        placeholder={<div className="search-placeholder">{t("general.search")}</div>}
        inputValue={inputValue}
        isLoading={isLoading}
        loadingMessage={(): string => `${t("general.loading")}...`}
        options={formattedOptions}
        formatOptionLabel={(option, formatOptionLabelMeta): JSX.Element =>
          formatOptionLabel(option, formatOptionLabelMeta.inputValue)
        }
        formatGroupLabel={formatGroupLabel}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null,
          GroupHeading,
          Input: CustomInput,
        }}
        styles={customStyles}
        filterOption={null}
        noOptionsMessage={(obj): ReactNode => noOptionsMessage(obj.inputValue)}
        onFocus={(): void => setIsExpanded(true)}
        onBlur={(): void => setIsExpanded(false)}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isRtl={isRtl}
      />
    </m.div>
  );
};

export default MainSearch;
