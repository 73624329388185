import React, { FC, ReactElement, ReactNode } from "react";
import { Button, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { IconChevronDownSVG } from "@epignosis_llc/gnosis/icons";
import { ButtonWithDropdownStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";
import { useApplyTranslations } from "@hooks";

type PlacementOptions = "bottom-start" | "bottom-end" | "top-start" | "top-end" | "end-top";

type ButtonWithDropdownProps = {
  children: ReactNode;
  list: DropdownItem[];
  color?: "primary" | "secondary" | "danger" | "success";
  disabled?: boolean;
  isLoading?: boolean;
  shouldHideEllipsis?: boolean; // This prop overrides the max-width property on the dropdown list
  placement?: PlacementOptions;
  btnIcon?: ReactElement;
  isBlock?: boolean;
  className?: string;
  onButtonClick?: () => void;
  onListItemClick?: (item: DropdownItem) => void;
};

const ButtonWithDropdown: FC<ButtonWithDropdownProps> = ({
  children,
  list,
  disabled,
  onButtonClick,
  onListItemClick,
  color = "primary",
  isLoading = false,
  btnIcon,
  shouldHideEllipsis = false,
  placement = "bottom-start",
  isBlock = false,
  className,
}) => {
  const { t } = useApplyTranslations();
  const hasList = list.length > 0;

  return (
    <div
      css={(): SerializedStyles => ButtonWithDropdownStyles(hasList, shouldHideEllipsis, disabled)}
      className={className ?? ""}
    >
      <Button
        block={isBlock}
        color={color}
        className="start-button"
        onClick={onButtonClick}
        disabled={disabled}
        isLoading={isLoading}
      >
        {btnIcon}
        <span className="btn-with-dropdown-text">{children}</span>
      </Button>

      {hasList && !disabled && (
        <>
          <div className="vertical-line" />
          <Dropdown list={list} onListItemSelect={onListItemClick} placement={placement}>
            <Button
              color={color}
              className="end-button"
              aria-label={`${children} ${t("a11y.button.options")}`}
              noGutters
            >
              <IconChevronDownSVG height={24} />
            </Button>
          </Dropdown>
        </>
      )}
    </div>
  );
};

export default ButtonWithDropdown;
